<template>
<!--关于我们头部-->
  <div class="topUsIntroduce">
    <div class="top_show wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="show_title white font30rem flexcc">{{$t('新零售数字化转型专家')}}</div>
      <div class="show_mean white font12rem flexcc">
        {{$t('行业领先新零售一体化系统及解决方案服务商')}}
      </div>
      <div class="show_contact flexcc">
        <div class="btn_contact flexcc blue1 font10rem fontweight" @click="showPopup">
          {{$t('联系商务合作')}}
        </div>
      </div>
    </div>
    
    <div class="popup" v-show="show==true">
      <ContactPopUp @closePopup="closePopup"></ContactPopUp>
    </div>
  </div>
</template>

<script>
import ContactPopUp from "@/components/ContactPopUp.vue";
export default {
  name: "topUsIntroduce",
  props: [],
  data() {
    return {
      pic: require("../assets/Images/ustbg.png"),
      selected: "home",
      isFixed: true,
      show:false
    };
  },
  mounted() {
     new this.$wow.WOW({ live: false }).init();
    this.changeTab();
    //window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  components: {ContactPopUp},
  watch: {},
  methods: {
    closePopup(style) {
      this.show = style;
    },
    showPopup() {
      this.show = true;
    },
    tabContentSel(id, e) {
      console.log(id);
      $(e).addClass("ui_tab_item_on").siblings().removeClass("ui_tab_item_on");
    },
    toFather(time) {
      this.$emit("fatherMethod", time);
    },
    changeTab() {
      $(".ui_tab_item").click(function () {
        $(this)
          .addClass("ui_tab_item_on")
          .siblings()
          .removeClass("ui_tab_item_on");
      });
    },
    // handleScroll() {
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop; // 滚动条偏移量
    //   let offsetTop = document.querySelector(".topIntroduce").offsetTop; // 要滚动到顶部吸附的元素的偏移量
    //   this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    //   console.log(11111);
    // },
  },
};
</script>

<style scoped lang="scss">
.popup{
  width: 100%;
  height: 200%;
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  top:-37.5rem;
  z-index: 100;
  padding: 1px;
}
.topUsIntroduce {
  width: 100%;
  height: 100%;
  background-image: url("../assets/Images/ustbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top_show {
  width: 100%;
  height: 100%;
  }
  .show_title {
    width: 100%;
    height: 10%;
    padding-top: 13%;
  }
  .show_mean {
    width: 100%;
    height: 12%;
  }
  .show_contact {
    width: 100%;
    height: 8%;
    .btn_contact {
      background-color: #fff;
      border-radius: 8px;
      width: 10%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>
