<template>
    <!--公司动态-->
    <div class="zj_companyNews">
        <div
            class="cnname font16rem black wow bounceInLeft"
            data-wow-duration="2s"
            data-wow-delay="0s"
            data-wow-offset="10"
            data-wow-iteration="1"
        >
            {{ $t("公司动态") }}
        </div>
        <div
            class="nameline wow bounceInLeft"
            data-wow-duration="2s"
            data-wow-delay="0s"
            data-wow-offset="10"
            data-wow-iteration="1"
        >
            <div class="line"></div>
        </div>
        <div
            class="news wow bounceInUp"
            v-show="news != []"
            data-wow-duration="2s"
            data-wow-delay="0s"
            data-wow-offset="10"
            data-wow-iteration="1"
        >
            <div class="swiper-container news_container">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="item in news"
                        :key="item.index"
                    >
                        <div class="sbg">
                            <div class="newname flexcc flexlrbetween">
                                <div class="date flextbc font12rem black">
                                    <span class="">{{ item.month }}</span>
                                    <span>{{ $t("月") }}</span>
                                    <span class="">{{ item.day }}</span>
                                    <span>{{ $t("日") }}</span>
                                </div>
                                <div class="year flexlrend flextbc font10rem">
                                    {{ item.year }}
                                </div>
                            </div>
                            <div class="newdetail font8rem black2" v-if="language=='CN'">
                                {{ item.detail }}
                            </div>
                            <div class="newdetail font8rem black2" v-if="language=='EN'">
                                {{ item.endetail }}
                            </div>
                            <div class="newpic flexcc flextbend">
                                <img :src="item.pic" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-button-prev"></div>
                <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
                <div class="swiper-button-next"></div>
                <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            </div>
        </div>
    </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
    name: "zj_companyNews",
    data() {
        return {
            news: [],
            language:localStorage.getItem("language")
        };
    },
    watch: {},
    created() {
        this.getData();
    },
    mounted() {
        new this.$wow.WOW({ live: false }).init();
        //this.swiperNews();
        console.log(this.ImgbaseURL);
        console.log(this.language);
    },
    methods: {
        swiperNews() {
            let swipernews = new Swiper(".news_container", {
                direction: "horizontal",
                autoplay: 3000,
                loop: true,
                speed: 1000,
                autoplayDisableOnInteraction: false,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                pagination: ".swiper-pagination",
                paginationClickable: true,
                grabCursor: true, //鼠标光标
                nextButton: ".swiper-button-next",
                prevButton: ".swiper-button-prev",
                //centeredSlides: true,
                //initialSlide :0,
                slidesPerView: 3,
                spaceBetween: "0%",
            });
        },
        getData() {
            this.news = [];
            // this.news = [
            //   {
            //     year: "2020",
            //     month: "11",
            //     day: "04",
            //     detail:
            //       "魔元术斩获“苏州银行杯”中国苏州（常熟）工业互联网创新创业大赛三等奖",
            //       endetail:'test1',
            //     pic: require("../assets/Images/news/news2.png"),
            //   },
            //   {
            //     year: "2020",
            //     month: "10",
            //     day: "27",
            //     detail: "魔元术受邀参加两化融合暨工业互联网平台大会",
            //     endetail:'test2',
            //     pic: require("../assets/Images/news/news3.png"),
            //   },
            //   {
            //     year: "2019",
            //     month: "06",
            //     day: "26",
            //     detail: "2019年度苏州市人工智能和大数据企业评选中，魔元术斩获殊荣",
            //     endetail:'test3',
            //     pic: require("../assets/Images/news/news1.png"),
            //   },
            // ];
            let that = this;
            this.$axios
                .post(this.url + "/Company/getCompanyNewsList")
                .then((res) => {
                    console.log(res.data);
                    this.news = res.data.list;
                    let list = res.data.list;
                    //let listobj={};
                    console.log(list[0]);
                    let ll = [];
                    for (let i = 0; i < list.length; i++) {
                        let listobj = {
                            day: list[i].day,
                            detail: list[i].detail,
                            endetail:list[i].endetail,
                            id: list[i].id,
                            month: list[i].month,
                            // pic: this.ImgbaseURL + "/UploadFile"+list[i].pic,
                            pic: this.ImgbaseURL + list[i].pic,
                            year: list[i].year,
                        };
                        ll.push(listobj);
                        //this.news.push(listobj);
                    }
                    this.news = ll;
                    console.log(this.news);
                    this.$nextTick(() => {
                        console.log("doLayout");
                        that.swiperNews();
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>

<style scoped lang="scss">
.zj_companyNews {
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    background-image: url("../assets/Images/linebg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    .cnname {
        width: 70%;
        margin-left: 15%;
        margin-bottom: 1rem;
    }
    .nameline {
        width: 70%;
        margin-left: 15%;
        margin-bottom: 1rem;
        .line {
            width: 8rem;
            height: 0.3rem;
            background-color: #2473e3;
            border-radius: 10px;
        }
    }
    .news {
        width: 75%;
        height: 70%;
        margin: 0 auto;
        margin-top: 2rem;
        .news_container {
            width: 100%;
            height: 100%;
            .newpic {
                width: 100%;
                height: 64%;
                img {
                    width: 100%;
                }
            }
            .newname {
                width: 80%;
                height: 15%;
                margin: 0 auto;
                // padding-top: 2rem;
                .date {
                    width: 30%;
                    height: 100%;
                }
                .year {
                    width: 40%;
                    height: 100%;
                }
            }
            .newdetail {
                width: 80%;
                height: 15%;
                text-align: left;
                margin: 0 auto;
            }
        }
        .sbg {
            width: 80%;
            height: 100%;
            box-shadow: 0 0 10px rgba(88, 157, 226, 0.25);
            margin: auto;
        }
        .news_container .swiper-slide {
            padding-top: 1.5rem;
            width: 30%;
        }
        .swiper-button-prev,
        .swiper-button-next {
            color: #e7ebf8;
            background-image: none;
        }
        .swiper-wrapper{
          transform: translate3d(45px, 0, 0);
        }
    }
}
</style>
