<template>
<!--关于我们-->
  <div class="home">
    <div class="top">
      <top :selected="selected" :pic="pic"></top>
    </div>
    <div class="topIntroduce">
      <topUsIntroduce></topUsIntroduce>
    </div>
    <div class="homeContent">
      <div class="companyIntroduce" id="companyIntroduce">
        <companyIntroduce></companyIntroduce>
      </div>
      <!-- <div class="development">
        <development></development>
      </div> -->
      <div class="companyQualification" id="companyQualification">
        <companyQualification></companyQualification>
      </div>
      <div class="teamIntroduce" id="teamIntroduce">
        <teamIntroduce></teamIntroduce>
      </div>
      <div class="companyNews" id="companyNews">
        <companyNews></companyNews>
      </div>
      <div class="bottomIntroduce">
        <bottomIntroduce></bottomIntroduce>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
// @ is an alias to /src
import top from "@/components/top.vue";
import topUsIntroduce from "@/components/topUsIntroduce.vue";
import companyIntroduce from "@/components/companyIntroduce.vue";
//import development from "@/components/development.vue";
import companyQualification from "@/components/companyQualification.vue";
import teamIntroduce from "@/components/teamIntroduce.vue";
import companyNews from "@/components/companyNews.vue";
import bottomIntroduce from "@/components/bottomIntroduce.vue";

export default {
  name: "Home",
  data() {
    return {
      selected: "aboutUs",
      pic: require("../assets/Images/ustbg.png"),
    };
  },
  components: {
    top,
    topUsIntroduce,
    companyIntroduce,
   // development,
    companyQualification,
    teamIntroduce,
    companyNews,
    bottomIntroduce,
  },
  created() {
    //创建时执行跳转锚点位置
    // this.$nextTick(() => {
    //   this.getlocal();
    // });
  },
  methods: {
  //   goAnchor(selector) {
  //    var anchor = this.$el.querySelector(selector)
  //    document.documentElement.scrollTop = anchor.offsetTop
  // }
  //  getlocal() {
  //     //找到锚点id
  //     let selectId = localStorage.getItem("toId");
  //     let toElement = document.getElementById(selectId);
  //     //如果对应id存在，就跳转
  //     if (selectId) {
  //       console.log(toElement,'toElement')
  //       toElement.scrollIntoView(true)
  //     }
  //   },
  //   //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  //   destroyed() {
  //     localStorage.setItem("toId", "");
  //   },
  },
  mounted() {
     new this.$wow.WOW({live: false}).init();
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar-track-piece { //滚动条凹槽的颜色，还可以设置边框属性
background-color:#f8f8f8;
}
::-webkit-scrollbar {//滚动条的宽度
width:9px;
height:9px;
z-index: 999;
}
::-webkit-scrollbar-thumb {//滚动条的设置
background-color:#dddddd;
background-clip:padding-box;
min-height:28px;
-webkit-border-radius: 2em;
 -moz-border-radius: 2em;
 border-radius:2em;
}
::-webkit-scrollbar-thumb:hover {
background-color:#bbb;
}
// ::-webkit-scrollbar {
//   display: none;
// }
.home {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  .top {
    width: 100%;
    height: 3.5rem;
    position: fixed;
    z-index: 100;
  }
  .topIntroduce {
    width: 100%;
    height: 37.5rem;
  }
  .homeContent {
    width: 100%;
    //height: 100%;
    background-image: url("../assets/Images/bg.png");
    //background-position: 0%;
    background-repeat: repeat;
    background-size: 100%;
    .companyIntroduce {
      width: 100%;
      height: 50rem;
    }
    .development{
      width: 100%;
      height:25rem;
    }
    .companyQualification{
      width: 100%;
      height:50rem;
    }
    .teamIntroduce{
      width: 100%;
      height:90rem;
    }
    .companyNews{
      width: 100%;
      height:32rem;
      margin-bottom: 5rem;
    }
  }
}
</style>