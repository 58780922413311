<template>
  <!--团队介绍-->
  <div class="zj_teamIntroduce">
    <div
      class="cnname font16rem black wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
   {{$t('团队介绍')}}  
    </div>
    <div
      class="nameline wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>
    <div class="team">
      <div :class="$i18n.locale == 'CN'?'teamline':'teamlineEN'">
        <div class="teamline_info flexrow flexlraround">
          <div
            class="staff wow"
            v-for="item in manager1"
            :key="item.index"
            :class="item.index == 0 ? 'bounceInLeft' : 'bounceInRight'"
            data-wow-duration="2s"
            data-wow-delay="0s"
            data-wow-offset="10"
            data-wow-iteration="1"
          >
            <div class="staff_photo flexlrc flextbend">
              <img :src="item.pic" />
            </div>
            <div class="staff_info">
              <div class="name flexcc font12rem fontweight black">
                {{ item.name }}
              </div>
              <div class="position flexcc font10rem black2">
                {{ item.position }}
              </div>
              <div class="info font8rem black2">
                {{ item.info }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="$i18n.locale == 'CN'?'teamline':'teamlineEN'">
        <div class="teamline_info flexrow flexlraround">
          <div
            class="staff wow"
            v-for="item in manager2"
            :key="item.index"
            :class="item.index == 2 ? 'bounceInLeft' : 'bounceInRight'"
            data-wow-duration="2s"
            data-wow-delay="0s"
            data-wow-offset="10"
            data-wow-iteration="1"
          >
            <div class="staff_photo flexlrc flextbend">
              <img :src="item.pic" />
            </div>
            <div class="staff_info">
              <div class="name flexcc font12rem fontweight black">
                {{ item.name }}
              </div>
              <div class="position flexcc font10rem black2">
                {{ item.position }}
              </div>
              <div class="info font8rem black2">
                {{ item.info }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
//import {WOW} from 'wowjs'
export default {
  name: "zj_teamIntroduce",
  data() {
    return {
      manager1: [
        {
          index: 0,
          name:this.$t("张学良") ,
          position:this.$t("创始人兼CEO") ,
          info:
           this.$t("比利时列日大学硕士，中国管理科学研究院学术委员会特约研究员、姑苏科技创业领军人才、常熟市“科技创新创业领军人才”（A类），资深项目管理专家，国内首批获得PMP证书的专业项目管理人才，拥有18年以上零售行业开发和项目管理经验，国内“一个公司一套业务系统”提出者和缔造者,且是一体化先行与倡导者。获得过最佳管理、最佳项目、昆山市人才等多项荣誉，曾担任江苏省专家资源库专家，现为苏州市工信领域大数据行业专家。") ,
          pic: require("../assets/Images/staff/zxl.png"),
        },
        {
          index: 1,
          name:this.$t("宋之民") ,
          position: "CTO",
          info:
           this.$t( "毕业于北京大学信息管理与系统专业，资深平台专家，已有18年零售行业平台和定制经验。对平台抽象开发、零售业务开发以及接口抽象开发等有丰富的经验，有移动互联网行业丰富的流程、现场开发与团队开发管理经验。"),
          pic: require("../assets/Images/staff/szm.png"),
        },
      ],

      manager2: [
        {
          index: 2,
          name:this.$t("曹小强") ,
          position: "COO",
          info:
            this.$t("毕业于中国地质大学计算机应用专业，是中国较早从事计算机开发的人才,且是国内资深的数据库开发专家，对平台配置和优化有丰富的经验,有丰富的零售管理经验;具有19年鞋服行业信息化项目经验。"),
          pic: require("../assets/Images/staff/cxq.png"),
        },
        {
          index: 3,
          name: this.$t("吴昌成"),
          position:this.$t( "开发总监"),
          info:
           this.$t( "曾任盐城师范学院信息与计算科学专业老师，拥有13年的.NET开发经验,是开发创新型人才。精通.NET Framework框架、熟悉Entity Framework、MVC框架； 熟练掌握WCF；熟练应用RabbitMQ、Kafka等Q类组件应用技术,Redis、Memcached等缓存类组件应用技术 ；理解各种设计模式及其使用场景，精通多线程开发。"),
          pic: require("../assets/Images/staff/wcc.png"),
        },
      ],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: false }).init();
  },
  methods: {
    clickTo() {
      console.log("跳转至查看更多页面");
    },
  },
};
</script>

<style scoped lang="scss">
.zj_teamIntroduce {
  width: 100%;
  height: 100%;
  .cnname {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .team {
    width: 100%;
    height: 80%;
    //margin-left: 10%;
    .teamline {
      width: 100%;
      height: 50%;
      margin-top: 5%;
      background-image: url("../assets/Images/casebg.png");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100% 70%;
      .teamline_info {
        width: 80%;
        height: 100%;
        margin-left: 10%;
      }
      .staff {
        width: 35%;
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
        .staff_photo {
          width: 100%;
          height: 45%;
          border-radius: 10px 10px 0 0;
          background-image: url("../assets/Images/teambg2.png");
          background-position: 50% 100%;
          background-repeat: no-repeat;
          background-size: 100%;
          img {
            width: 60%;
          }
        }
        .staff_info {
          width: 80%;
          height: 55%;
          margin: 0 auto;
          .name {
            width: 100%;
            height: 15%;
          }
          .position {
            width: 100%;
            height: 5%;
          }
          .info {
            width: 100%;
            height: 75%;
            margin-top: 5%;
            line-height: 1.4rem;
          }
        }
      }
    }
    .teamlineEN {
      width: 100%;
      min-height: 50%;
      margin-top: 4%;
      background-image: url("../assets/Images/casebg.png");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100% 70%;
      .teamline_info {
        width: 80%;
        height: 100%;
        margin-left: 10%;
      }
      .staff {
        width: 35%;
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
        .staff_photo {
          width: 100%;
          height: 45%;
          border-radius: 10px 10px 0 0;
          background-image: url("../assets/Images/teambg2.png");
          background-position: 50% 100%;
          background-repeat: no-repeat;
          background-size: 100%;
          img {
            width: 60%;
          }
        }
        .staff_info {
          width: 80%;
          height: 55%;
          margin: 0 auto;
          .name {
            width: 100%;
            height: 15%;
          }
          .position {
            width: 100%;
            height: 5%;
          }
         .info {
            width: 105%;
            min-height: 300px;
            margin-top: 2%;
            line-height: 1.1rem;
          }
        }
      }
    }
  }
}
</style>
