<template>
  <!--公司介绍-->
  <div class="zj_aboutus flexrow">
    <div
      class="aboutus_pic wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    ></div>
    <div
      class="aboutus_info wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="us black font16rem black">{{$t('公司介绍')}}</div>
      <div class="usline"></div>
      <div :class="$i18n.locale == 'CN'?'usinfo1 font8rem black2':'usinfo1EN font8rem black2'">
        {{$t('魔元术公司于2018年7月在常熟成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。')}}
      </div>
      <div :class="$i18n.locale == 'CN'?'usinfo2 font8rem black2':'usinfo2EN font8rem black2'">
        {{$t('公司专业从事零售、服装行业、运动细分行业的移动互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理。通过运用大数据、人工智能等先进技术，对零售业商品的生产、流通与销售过程进行升级改造，帮助企业建立数字化竞争优势，达成渠道一体化、卖场智能化、商品社会化，打造经营数字化（主要包括营销数字化、门店数字化、供应链数字化、店员数字化、会员数字化、商品数字化的企业数字化进程问题）。')}}
      </div>
      <div :class="$i18n.locale == 'CN'?'usinfo2 font8rem black2':'usinfo2EN font8rem black2'">
        {{$t('具有目前移动互联网行业内最先进的积木式、魔方式的快速配置、开发、交付能力，现已成为该行业内最大的模块化与开发软件的平台，彻底解决了零售批发电商一体化的软件问题。')}}
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  name: "zj_aboutus",
  data() {
    return {};
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: false }).init();
  },
  methods: {
    clickTo() {
      console.log("跳转至查看更多页面");
    },
  },
};
</script>

<style scoped lang="scss">
.zj_aboutus {
  width: 100%;
  height: 100%;
  background-image: url("../assets/Images/dotbg.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 20%;
  .aboutus_pic {
    width: 50%;
    height: 100%;
    background-image: url("../assets/Images/uspic2.jpg");
    background-position: 80% 60%;
    background-repeat: no-repeat;
    background-size: 70%;
  }
  .aboutus_info {
    width: 50%;
    height: 100%;
  }
  .us {
    margin-top: 20%;
  }
  .usline {
    width: 5rem;
    height: 0.3rem;
    background-color: #2473e3;
    border-radius: 10px;
    margin-top: 3%;
  }
  .usinfo1,
  .usinfo2 {
    width: 70%;
    line-height: 1.5rem;
    margin-top: 4%;
  }
  .usinfo1EN,
  .usinfo2EN {
    width: 70%;
    line-height: 1.2rem;
    margin-top: 1%;
    text-align: left;
  }
  .more {
    width: 100%;
    height: 5rem;
    margin-top: 4%;
    .cha {
      padding: 2rem 0 2rem 2rem;
      background-color: rgba(36, 115, 227, 0.1);
    }
    .jt {
      margin-left: 1rem;
    }
  }
}
</style>
